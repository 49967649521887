import { IDataUser, IDataUserFlags, IDataUsersResponse, IUserFlags, IUserItem, IUsersResponse } from 'assets/ts/types';
import { convertRole } from 'assets/ts/helpers/convert-data/user-roles';

const convertUserFlags = ({ buttons }: IDataUserFlags) =>
    ({
        flags: buttons ? [...buttons] : [],
    } as IUserFlags);

const convertUser = (user: IDataUser) => {
    const {
        email,
        role,
        active,
        first_name,
        last_name,
        patronymic_name,
        partner_name,
        trade_name,
        bitrix_id,
        rs_id,
        show_buttons,
        admin_info,
        show_graviton_lk_link,
        user_note,
        show_reinvite_button,
        is_extended_permissions,
        ...rest
    } = user;
    return {
        login: email || '',
        isActive: active,
        firstName: first_name,
        lastName: last_name,
        patronymic: patronymic_name,
        partnerName: partner_name,
        partnerTradeName: trade_name,
        bitrixId: bitrix_id,
        rsId: rs_id,
        note: user_note,
        adminInfo: admin_info
            ? {
                  createdBy: admin_info.created_by,
                  createdDate: admin_info.created_at,
                  updatedBy: admin_info.updated_by,
                  updatedDate: admin_info.updated_at,
                  roleUpdatedBy: admin_info.role_updated_by,
                  roleUpdatedDate: admin_info.role_updated_at,
                  roleBefore: admin_info.role_before,
              }
            : null,
        flags: [],
        notificationsAmount: {
            news: 0,
            feedback: 0,
        },
        reInvitationAvailable: show_reinvite_button,
        extendedPermissions: is_extended_permissions,
        ...convertRole(role),
        ...(show_buttons !== undefined ? { showButtons: show_buttons } : {}),
        ...(show_graviton_lk_link !== undefined ? { showGravitonLkLink: show_graviton_lk_link } : {}),
        ...rest,
    } as IUserItem;
};

const convertUsersData = ({ users, page, pages_total, prev, next, count }: IDataUsersResponse) =>
    ({
        users: users.map(user => convertUser(user)),
        usersTotal: count,
        page,
        pagesTotal: pages_total,
        pagePrev: prev,
        pageNext: next,
    } as IUsersResponse);

export default convertUsersData;
export { convertUserFlags, convertUser };
