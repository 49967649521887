import { store } from 'store';
import { logOut } from 'store/reducers/user';
import {
    ICategorySettingsAttributeVirtual,
    ICategorySettingsAttributesCommonValues,
    IConfigurationFormFiltersValues,
    IConfigurationFormSearchAttributeOptions,
    IConfigurationSave,
    IConfigurationSaveFields,
    IConfigurationsRequest,
    IDataCategorySettingsAttributeVirtual,
    IDataStorage,
    IEducationItemSave,
    IEducationItemSaveRequest,
    IFeedbackItemCreateRequest,
    IFeedbackItemUpdateRequest,
    IFetchContent,
    IFetchProps,
    INewsAdminItemSave,
    IPricesMonitoringRequest,
    IProductAssociationsItem,
    IProductSave,
    IProfileSave,
    IRequestCategoryProducts,
    IRequestConfProduct,
    IRequestConfValidateAndSum,
    IRequestList,
    IRequestListConfigurations,
    IRequestListFeedback,
    IStorage,
    IUserItem,
    IUserSave,
    IUserSaveApp,
    TFetchError,
    TRequestType,
} from 'assets/ts/types';
import { columnsNames } from 'assets/ts/helpers/convert-data/category-products';
import getError from 'assets/ts/helpers/get-error';
import {
    ACTION_ID_ASSOCIATIONS_FETCH_EXCEL,
    ACTION_ID_ASSOCIATIONS_UPLOAD_EXCEL,
    ACTION_ID_CATEGORIES_FETCH,
    ACTION_ID_CATEGORIES_FETCH_SETTINGS,
    ACTION_ID_CATEGORIES_SAVE,
    ACTION_ID_COEFFS_FETCH,
    ACTION_ID_COEFFS_SAVE,
    ACTION_ID_CONFIGURATIONS_COPY,
    ACTION_ID_CONFIGURATIONS_DELETE,
    ACTION_ID_CONFIGURATIONS_FETCH,
    ACTION_ID_CONFIGURATIONS_FETCH_DETAIL,
    ACTION_ID_CONFIGURATIONS_FETCH_EXCEL,
    ACTION_ID_CONFIGURATIONS_FETCH_HISTORY,
    ACTION_ID_CONFIGURATIONS_FETCH_SUM,
    ACTION_ID_CONFIGURATIONS_REFRESH_PRICES,
    ACTION_ID_CONFIGURATIONS_SAVE,
    ACTION_ID_CONFIGURATIONS_SEND_TO_CRM,
    ACTION_ID_CONFIGURATIONS_SPECIFY,
    ACTION_ID_CONFIGURATIONS_UPLOAD,
    ACTION_ID_CONFIGURATIONS_UPLOAD_CANCEL,
    ACTION_ID_CONFIGURATOR_FETCH_FIELDS,
    ACTION_ID_CONFIGURATOR_FETCH_FILTERS,
    ACTION_ID_CONFIGURATOR_FETCH_RANDOM_ARTICUL,
    ACTION_ID_CONFIGURATOR_FETCH_SLOT_SEARCH_PRODUCTS,
    ACTION_ID_CONFIGURATOR_FETCH_SLOT_SEARCH_PRODUCTS_ATTRIBUTES,
    ACTION_ID_CONFIGURATOR_INIT,
    ACTION_ID_CONFIGURATOR_SET_SLOT_SEARCH_PRODUCTS_RECOMMENDATION,
    ACTION_ID_CONFIGURATOR_VALIDATE,
    ACTION_ID_DIFFERENT_USERS_LOGIN,
    ACTION_ID_EDUCATION_ADMIN_ITEM_DELETE,
    ACTION_ID_EDUCATION_ADMIN_ITEM_FETCH,
    ACTION_ID_EDUCATION_ADMIN_ITEM_UPDATE,
    ACTION_ID_EDUCATION_FETCH,
    ACTION_ID_FEEDBACK_ADMIN_FETCH,
    ACTION_ID_FEEDBACK_ADMIN_ITEM_FETCH,
    ACTION_ID_FEEDBACK_ITEM_CREATE,
    ACTION_ID_FEEDBACK_ITEM_UPDATE,
    ACTION_ID_NEWS_ADMIN_FETCH,
    ACTION_ID_NEWS_ADMIN_ITEM_DELETE,
    ACTION_ID_NEWS_ADMIN_ITEM_FETCH,
    ACTION_ID_NEWS_ADMIN_ITEM_UPDATE,
    ACTION_ID_NEWS_FETCH,
    ACTION_ID_NEWS_FETCH_DETAIL,
    ACTION_ID_NOTIFICATIONS_FETCH,
    ACTION_ID_PAGES_FETCH,
    ACTION_ID_PRICES_MONITORING_FETCH,
    ACTION_ID_PRICES_MONITORING_FETCH_EXCEL,
    ACTION_ID_PRICES_MONITORING_FETCH_PRICES,
    ACTION_ID_PRODUCTS_DELETE,
    ACTION_ID_PRODUCTS_EXCEL_UPLOAD,
    ACTION_ID_PRODUCTS_FETCH,
    ACTION_ID_PRODUCTS_FETCH_ASSOCIATIONS,
    ACTION_ID_PRODUCTS_FETCH_EXCEL,
    ACTION_ID_PRODUCTS_FETCH_FORM,
    ACTION_ID_PRODUCTS_FETCH_IMAGE,
    ACTION_ID_PRODUCTS_FETCH_PRODUCT_PRICE_AND_AMOUNT,
    ACTION_ID_PRODUCTS_FETCH_PRODUCT_PRICE_AND_REPORT,
    ACTION_ID_PRODUCTS_IMAGE_UPLOAD,
    ACTION_ID_PRODUCTS_SAVE,
    ACTION_ID_PRODUCTS_SAVE_ASSOCIATIONS,
    ACTION_ID_PROJECTS_DELETE,
    ACTION_ID_PROJECTS_FETCH,
    ACTION_ID_PROJECTS_SAVE,
    ACTION_ID_STORAGES_FETCH,
    ACTION_ID_STORAGES_SAVE,
    ACTION_ID_USERS_CHECK_TOKEN,
    ACTION_ID_USERS_COMPANY_INN_FETCH,
    ACTION_ID_USERS_CONFIRM_EMAIL,
    ACTION_ID_USERS_DEACTIVATE,
    ACTION_ID_USERS_FETCH,
    ACTION_ID_USERS_FETCH_APPS,
    ACTION_ID_USERS_FETCH_FLAGS,
    ACTION_ID_USERS_FETCH_ROLES,
    ACTION_ID_USERS_FETCH_RS,
    ACTION_ID_USERS_FETCH_USER,
    ACTION_ID_USERS_INVITE,
    ACTION_ID_USERS_LOGIN,
    ACTION_ID_USERS_PROFILE_FETCH,
    ACTION_ID_USERS_PROFILE_SAVE,
    ACTION_ID_USERS_REGISTER,
    ACTION_ID_USERS_REINVITE,
    ACTION_ID_USERS_RESTORE_PASSWORD,
    ACTION_ID_USERS_RESTORE_PASSWORD_CHECK,
    ACTION_ID_USERS_RESTORE_PASSWORD_LINK,
    ACTION_ID_USERS_SAVE,
    ACTION_ID_USER_SETTINGS_FETCH,
} from 'assets/ts/constants';

const { REACT_APP_COMPANY: company, REACT_APP_API_PATH } = process.env;
const controllers: { id: string; controller: AbortController }[] = [];

class Actions {
    _apiBase = REACT_APP_API_PATH;

    async getData({ id, url, method = 'GET', request, token: tokenStr, requestType = 'json' }: IFetchProps) {
        const controller = new AbortController();
        const { signal } = controller;
        const { token } = store.getState().user;
        const tokenUltimate = tokenStr || token;
        const content: IFetchContent = {
            method,
            headers: {},
            signal,
        };

        if (controllers.find(item => item.id === id)) {
            this.abort(id);
        }

        controllers.push({ id, controller });

        if (requestType !== 'formData') {
            content.headers['Content-Type'] = 'application/json';
        }
        if (tokenUltimate) {
            content.headers['Authorization'] = `Bearer ${tokenUltimate}`;
        }
        if (request) {
            content.body = request;
        }

        try {
            const response = await fetch(`${this._apiBase}${url}`, content);
            if (!response.ok) {
                if (response.status === 401) {
                    store.dispatch(logOut());
                }
                throw new Error(getError((await setResponse(response, 'json')) as TFetchError));
            }
            this.cleanController(id);
            return setResponse(response, requestType);
        } catch (error) {
            throw new Error(getError(error as TFetchError));
        }
    }

    abort(id: string) {
        this.cleanController(id, true);
    }

    cleanController(id: string, abort: boolean = false) {
        const requestIndex = controllers.findIndex(item => item.id === id);
        if (requestIndex > -1) {
            abort && controllers[requestIndex].controller.abort();
            controllers.splice(requestIndex, 1);
        }
    }

    // APPS
    fetchAppsAvailable() {
        return this.getData({ id: ACTION_ID_USERS_FETCH_APPS, url: '/users/apps/' });
    }

    fetchUserSettings(currentApp: string) {
        return this.getData({ id: ACTION_ID_USER_SETTINGS_FETCH, url: `/${currentApp}/user-settings` });
    }

    fetchNotifications() {
        return this.getData({
            id: ACTION_ID_NOTIFICATIONS_FETCH,
            url: '/notifications',
        });
    }

    fetchCoeffs(currentAppName: string) {
        return this.getData({ id: ACTION_ID_COEFFS_FETCH, url: `/settings/coeffs?app_type=${currentAppName}` });
    }

    saveCoeffs(coeffs: { id: number; value: number }[]) {
        const request = [...coeffs];
        return this.getData({
            id: ACTION_ID_COEFFS_SAVE,
            url: '/settings/coeffs',
            request: JSON.stringify(request),
            method: 'PATCH',
        });
    }

    fetchStorages(currentAppName: string) {
        return this.getData({ id: ACTION_ID_STORAGES_FETCH, url: `/settings/${currentAppName}/stores` });
    }

    saveStorages(currentAppName: string, storages: IStorage[]) {
        const request: IDataStorage[] = storages.map(s => ({
            store_id: s.id,
            store_uid: s.uid,
            name: s.name,
            is_select: s.selected,
        }));
        return this.getData({
            id: ACTION_ID_STORAGES_SAVE,
            url: `/settings/${currentAppName}/stores`,
            request: JSON.stringify(request),
            method: 'PUT',
        });
    }

    fetchAssociationsExcel(currentApp: string) {
        return this.getData({ id: ACTION_ID_ASSOCIATIONS_FETCH_EXCEL, url: `/apps/${currentApp}/associations`, requestType: 'file' });
    }

    uploadAssociationsExcel(formData: FormData, currentApp: string) {
        const request = formData;
        return this.getData({
            id: ACTION_ID_ASSOCIATIONS_UPLOAD_EXCEL,
            url: `/apps/${currentApp}/associations`,
            method: 'POST',
            request,
            requestType: 'formData',
        });
    }

    fetchAccessiblePages(currentApp: string) {
        return this.getData({ id: ACTION_ID_PAGES_FETCH, url: `/users/${currentApp}/pages` });
    }

    // USERS
    inviteUser(user: Partial<IUserItem>, apps: IUserSaveApp[]) {
        const { login, lastName, firstName, patronymic, roleCode, phone, partnerName, partnerTradeName, inn, bitrixId, rsId, note } = user;
        let request: IUserSave = {
            email: login || null,
            last_name: lastName || null,
            first_name: firstName || null,
            patronymic_name: patronymic || null,
            role: roleCode || null,
            phone: phone || null,
            partner_name: partnerName || null,
            trade_name: partnerTradeName || null,
            inn: inn || null,
            bitrix_id: bitrixId || null,
            rs_id: rsId || null,
            user_note: note || null,
            apps,
        };

        return this.getData({
            id: ACTION_ID_USERS_INVITE,
            url: `/users/invite`,
            method: 'POST',
            request: JSON.stringify(request),
        });
    }

    reInviteUser(id: number) {
        return this.getData({ id: ACTION_ID_USERS_REINVITE, url: `/users/reinvite/${id}` });
    }

    registerUser(user: Partial<IUserItem>, token: string) {
        const { password, lastName, firstName, patronymic, phone, partnerName, partnerTradeName, inn, note } = user;
        let request: IProfileSave = {
            password: password || null,
            last_name: lastName || null,
            first_name: firstName || null,
            patronymic_name: patronymic || null,
            phone: phone || null,
            partner_name: partnerName || null,
            trade_name: partnerTradeName || null,
            inn: inn || null,
            user_note: note || null,
        };

        return this.getData({
            id: ACTION_ID_USERS_REGISTER,
            url: `/register/${token}`,
            method: 'POST',
            request: JSON.stringify(request),
        });
    }

    confirmEmail(token: string) {
        return this.getData({ id: ACTION_ID_USERS_CONFIRM_EMAIL, url: `/register/${token}` });
    }

    sendRestorePasswordLink(email: string) {
        return this.getData({
            id: ACTION_ID_USERS_RESTORE_PASSWORD_LINK,
            url: '/forgot-password',
            method: 'POST',
            request: JSON.stringify({ email, company }),
        });
    }

    checkRestorePasswordToken(token: string) {
        return this.getData({ id: ACTION_ID_USERS_RESTORE_PASSWORD_CHECK, url: `/reset-password/${token}` });
    }

    restorePassword(token: string, password: string) {
        const request = JSON.stringify({ token, password, company });
        return this.getData({
            id: ACTION_ID_USERS_RESTORE_PASSWORD,
            url: '/reset-password',
            method: 'POST',
            request,
        });
    }

    logIn(email: string, password: string) {
        const request = JSON.stringify({ email, password, company });
        return this.getData({ id: ACTION_ID_USERS_LOGIN, url: '/login', method: 'POST', request });
    }

    differentUserLogIn(email: string) {
        const request = JSON.stringify({ email });
        return this.getData({ id: ACTION_ID_DIFFERENT_USERS_LOGIN, url: '/login_different_user', method: 'POST', request });
    }

    checkToken(token: string) {
        return this.getData({ id: ACTION_ID_USERS_CHECK_TOKEN, url: '/check_auth_token', request: JSON.stringify({ token }), method: 'POST' });
    }

    fetchRoles() {
        return this.getData({ id: ACTION_ID_USERS_FETCH_ROLES, url: '/users/admin/roles' });
    }

    fetchUsers({ search, page, pageSize, appId }: IRequestList) {
        return this.getData({
            id: ACTION_ID_USERS_FETCH,
            url: `/users?search=${search}&page=${page}&page_size=${pageSize}${appId ? `&app_id=${appId}` : ''}`,
        });
    }

    fetchUser(id: number) {
        return this.getData({ id: ACTION_ID_USERS_FETCH_USER, url: `/users/${id}` });
    }

    saveUser(isNew: boolean, user: Partial<IUserItem>, apps: IUserSaveApp[]) {
        const {
            id,
            login,
            isActive,
            password,
            lastName,
            firstName,
            patronymic,
            roleId,
            roleCode,
            extendedPermissions,
            phone,
            partnerName,
            partnerTradeName,
            inn,
            bitrixId,
            rsId,
            apps: userApps,
            note,
            ...rest
        } = user;
        let request: IUserSave = {
            email: login || null,
            password: password || null,
            active: isActive,
            last_name: lastName || null,
            first_name: firstName || null,
            patronymic_name: patronymic || null,
            role: roleCode || null,
            is_extended_permissions: extendedPermissions,
            phone: phone || null,
            partner_name: partnerName || null,
            trade_name: partnerTradeName || null,
            inn: inn || null,
            bitrix_id: bitrixId || null,
            rs_id: rsId || null,
            apps,
            user_note: note || null,
            ...rest,
        };

        return this.getData({
            id: ACTION_ID_USERS_SAVE,
            url: `/users${!isNew ? `/${id}` : ''}`,
            method: isNew ? 'POST' : 'PATCH',
            request: JSON.stringify(request),
        });
    }

    deactivateUser(id: number) {
        return this.getData({
            id: ACTION_ID_USERS_DEACTIVATE,
            url: `/users/deactivate/${id}`,
            method: 'PATCH',
        });
    }

    fetchRsUsers() {
        return this.getData({ id: ACTION_ID_USERS_FETCH_RS, url: '/users/rs' });
    }

    fetchProfile(token?: string) {
        return this.getData({ id: ACTION_ID_USERS_PROFILE_FETCH, url: '/profile', token });
    }

    saveProfile(user: Partial<IUserItem>) {
        const { password, passwordOld, lastName, firstName, patronymic, phone, partnerName, partnerTradeName, inn, note } = user;
        let request: IProfileSave = {
            new_password: password || null,
            old_password: passwordOld || null,
            last_name: lastName || null,
            first_name: firstName || null,
            patronymic_name: patronymic || null,
            phone: phone || null,
            partner_name: partnerName || null,
            trade_name: partnerTradeName || null,
            inn: inn || null,
            user_note: note || null,
        };

        return this.getData({
            id: ACTION_ID_USERS_PROFILE_SAVE,
            url: '/profile',
            method: 'PATCH',
            request: JSON.stringify(request),
        });
    }

    fetchUserFlags() {
        return this.getData({ id: ACTION_ID_USERS_FETCH_FLAGS, url: '/users/buttons/' });
    }

    fetchCompanyNameByInn(inn: string) {
        return this.getData({ id: ACTION_ID_USERS_COMPANY_INN_FETCH, url: `/users/company/inn?inn=${inn}` });
    }

    // PRICES MONITORING
    fetchPricesMonitoringItems({ search, appId, category, pricesDifference, page, pageSize }: IPricesMonitoringRequest) {
        return this.getData({
            id: ACTION_ID_PRICES_MONITORING_FETCH,
            url: `/prices_monitoring?page=${page}&page_size=${pageSize}${search ? `&search=${search}` : ''}${appId ? `&app_id=${appId}` : ''}${
                category ? `&category_name=${category}` : ''
            }${pricesDifference ? `&price_difference=${pricesDifference}` : ''}`,
        });
    }

    fetchPricesMonitoringExcel({ search, appId, category, pricesDifference }: IPricesMonitoringRequest) {
        return this.getData({
            id: ACTION_ID_PRICES_MONITORING_FETCH_EXCEL,
            url: `/prices_monitoring/xlsx?${search ? `&search=${search}` : ''}${appId ? `&app_id=${appId}` : ''}${
                category ? `&category_name=${category}` : ''
            }${pricesDifference ? `&price_difference=${pricesDifference}` : ''}`,
            requestType: 'file',
        });
    }

    fetchPricesMonitoringProductsPrices({ search, appId, category, pricesDifference }: IPricesMonitoringRequest) {
        return this.getData({
            id: ACTION_ID_PRICES_MONITORING_FETCH_PRICES,
            url: `/prices_monitoring/prices?${search ? `&search=${search}` : ''}${appId ? `&app_id=${appId}` : ''}${
                category ? `&category_name=${category}` : ''
            }${pricesDifference ? `&price_difference=${pricesDifference}` : ''}`,
        });
    }

    // CONFIGURATIONS
    fetchConfigurationsList({
        priceCsGraviton,
        search,
        dates,
        page,
        pageSize,
        projectId,
        appId,
        onlyMy,
        onlyDraft,
        sort,
    }: IRequestListConfigurations) {
        return this.getData({
            id: ACTION_ID_CONFIGURATIONS_FETCH,
            url:
                `/${priceCsGraviton ? 'price-cs-graviton' : 'configurations'}` +
                `?search=${search}` +
                `&page=${page}` +
                `&page_size=${pageSize}` +
                `&from_date=${dates[0] ?? ''}` +
                `&to_date=${dates[1] ?? ''}` +
                `${appId ? `&app_id=${appId}` : ''}` +
                `${sort ? `&sort=${sort}` : ''}` +
                `${!priceCsGraviton && projectId ? `&project_id=${projectId}` : ''}` +
                `${!priceCsGraviton && onlyMy ? `&only_my=${onlyMy}` : ''}` +
                `${!priceCsGraviton && onlyDraft ? `&only_draft=${onlyDraft}` : ''}`,
        });
    }

    fetchConfigurationsExcel(
        configurations: (number | string)[],
        isExtended: boolean,
        isCommercialOffer: boolean,
        isForBuild: boolean,
        priceCsGraviton: boolean
    ) {
        let request: IConfigurationsRequest = { configurations };
        return this.getData({
            id: ACTION_ID_CONFIGURATIONS_FETCH_EXCEL,
            url:
                `/${priceCsGraviton ? 'price-cs-graviton' : 'configurations'}/xlsx` +
                `${isExtended ? '/presale' : isCommercialOffer ? '/kp' : isForBuild ? '/for_build' : priceCsGraviton ? '/price_cs_graviton' : ''}`,
            method: priceCsGraviton ? 'GET' : 'POST',
            request: !priceCsGraviton ? JSON.stringify(request) : '',
            requestType: 'file',
        });
    }

    sendToCRM(configurations: (number | string)[]) {
        let request: IConfigurationsRequest = { configurations };
        return this.getData({
            id: ACTION_ID_CONFIGURATIONS_SEND_TO_CRM,
            url: '/configurations/send_to_crm',
            method: 'POST',
            request: JSON.stringify(request),
        });
    }

    refreshConfigurationsPrices() {
        return this.getData({
            id: ACTION_ID_CONFIGURATIONS_REFRESH_PRICES,
            url: '/price-cs-graviton/prices_rrc',
        });
    }

    fetchConfigurationDetails(id: number) {
        return this.getData({ id: ACTION_ID_CONFIGURATIONS_FETCH_DETAIL, url: `/configurations/${id}` });
    }

    deleteConfiguration(id: number) {
        return this.getData({ id: ACTION_ID_CONFIGURATIONS_DELETE, url: `/configurations/${id}`, method: 'DELETE' });
    }

    copyConfiguration(id: number, typical: boolean) {
        return this.getData({
            id: ACTION_ID_CONFIGURATIONS_COPY,
            url: `/${typical ? 'price-cs-graviton' : 'configurations'}/copy/${id}`,
        });
    }

    configurationUpload(id: number) {
        return this.getData({ id: ACTION_ID_CONFIGURATIONS_UPLOAD, url: `/configurations/${id}/erp-upload` });
    }

    cancelConfigurationUpload(id: number) {
        return this.getData({ id: ACTION_ID_CONFIGURATIONS_UPLOAD_CANCEL, url: `/configurations/${id}/cancel-erp-upload` });
    }

    specifyConfiguration(id: number) {
        return this.getData({ id: ACTION_ID_CONFIGURATIONS_SPECIFY, url: `/configurations/${id}/specify` });
    }

    // CONFIGURATOR FORM
    initConfiguratorForm(id: number) {
        return this.getData({
            id: ACTION_ID_CONFIGURATOR_INIT,
            url: `/configurations-update/${id}`,
        });
    }

    validateConfiguratorForm({ app, ...rest }: IRequestConfValidateAndSum) {
        const request = { ...rest };

        return this.getData({
            id: ACTION_ID_CONFIGURATOR_VALIDATE,
            url: `/${app}/configurations/validate`,
            method: 'PUT',
            request: JSON.stringify(request),
        });
    }

    fetchConfiguratorFormFilters(app: string, id?: string | number) {
        return this.getData({
            id: ACTION_ID_CONFIGURATOR_FETCH_FILTERS,
            url: `/configurations/filters?app_name=${app.toUpperCase()}${id ? `&configurations_id=${id}` : ''}`,
        });
    }

    fetchConfiguratorFormFields(currentApp: string) {
        return this.getData({
            id: ACTION_ID_CONFIGURATOR_FETCH_FIELDS,
            url: `/configurations/${currentApp}/fields`,
        });
    }

    searchSlotProducts(
        currentApp: string,
        categoryName: string,
        search: string,
        searchAttributes: { identifier: string; value: IConfigurationFormSearchAttributeOptions }[]
    ) {
        const request = {
            regex: search,
            category_name: categoryName,
            search_attributes: searchAttributes,
        };
        return this.getData({
            id: ACTION_ID_CONFIGURATOR_FETCH_SLOT_SEARCH_PRODUCTS,
            url: `/${currentApp}/rs/items`,
            request: JSON.stringify(request),
            method: 'POST',
        });
    }

    fetchSearchSlotProductsAttributes(currentApp: string, virtualItemId: number) {
        return this.getData({
            id: ACTION_ID_CONFIGURATOR_FETCH_SLOT_SEARCH_PRODUCTS_ATTRIBUTES,
            url: `/${currentApp}/form_search/${virtualItemId}`,
        });
    }

    setSearchSlotProductsRecommendation(currentApp: string, articul: number, recommended: boolean) {
        return this.getData({
            id: ACTION_ID_CONFIGURATOR_SET_SLOT_SEARCH_PRODUCTS_RECOMMENDATION,
            method: recommended ? 'DELETE' : 'POST',
            url: `/${currentApp}/recommendations?pricelist_id=${articul}`,
        });
    }

    saveConfiguration(
        priceCsGraviton: boolean,
        sendToCRM: boolean,
        id: number,
        isNew: boolean,
        items: IRequestConfProduct[],
        count: number,
        filters: IConfigurationFormFiltersValues,
        app: string,
        fields?: IConfigurationSaveFields
    ) {
        let request: IConfigurationSave = {
            is_typical: priceCsGraviton,
            items,
            count,
            filters,
        };

        if (fields && Object.keys(fields).length) {
            request.fields = fields;
        }

        return this.getData({
            id: ACTION_ID_CONFIGURATIONS_SAVE,
            url: `/${isNew ? `${app}/` : ''}configurations${!isNew ? `/${id}` : ''}?send_crm=${sendToCRM}`,
            method: isNew ? 'POST' : 'PUT',
            request: JSON.stringify(request),
        });
    }

    fetchConfigurationSum({ app, ...rest }: IRequestConfValidateAndSum) {
        const request = { ...rest };

        return this.getData({
            id: ACTION_ID_CONFIGURATIONS_FETCH_SUM,
            url: `/${app}/configurations/price`,
            method: 'POST',
            request: JSON.stringify(request),
        });
    }

    fetchRandomArticulProduct(app: string, articul: string | number) {
        return this.getData({
            id: ACTION_ID_CONFIGURATOR_FETCH_RANDOM_ARTICUL,
            url: `/${app}/configurations/random-article/${articul}`,
        });
    }

    // CONFIGURATION HISTORY
    fetchConfigurationHistory(id: number) {
        return this.getData({ id: ACTION_ID_CONFIGURATIONS_FETCH_HISTORY, url: `/configurations-history/${id}` });
    }

    // PROJECTS
    fetchProjects({ search, page, pageSize }: IRequestList) {
        return this.getData({ id: ACTION_ID_PROJECTS_FETCH, url: `/projects?search=${search}&page=${page}&page_size=${pageSize}` });
    }

    fetchProjectSave(isNew: boolean, name: string, id?: number) {
        return this.getData({
            id: ACTION_ID_PROJECTS_SAVE,
            url: `/project${isNew ? '' : `/${id}`}?name=${name}`,
            method: isNew ? 'POST' : 'PATCH',
        });
    }

    fetchProjectDelete(id: number) {
        return this.getData({ id: ACTION_ID_PROJECTS_DELETE, url: `/project/${id}`, method: 'DELETE' });
    }

    // CATEGORIES
    fetchCategories(currentApp: string) {
        return this.getData({ id: ACTION_ID_CATEGORIES_FETCH, url: `/${currentApp}/categories` });
    }

    fetchCategorySettings(currentApp: string, category_name: string) {
        return this.getData({
            id: ACTION_ID_CATEGORIES_FETCH_SETTINGS,
            url: `/${currentApp}/${category_name}/settings`,
        });
    }

    updateCategorySettings(
        currentApp: string,
        category_name: string,
        sort: number,
        is_mandatory: boolean,
        is_virtual: boolean,
        attributesCommon: ICategorySettingsAttributesCommonValues = { fieldsMain: [], fieldsAttributes: [] },
        attributesVirtual: ICategorySettingsAttributeVirtual[] = []
    ) {
        const { fieldsMain, fieldsAttributes } = attributesCommon;
        const request = JSON.stringify({
            settings: { is_mandatory, sort, is_virtual },
            common_attributes: {
                fields_main: fieldsMain.map(field => {
                    const { title, required, disabled, ...rest } = field;
                    return { display_name: title, is_required: required, is_disabled: disabled, ...rest };
                }),
                fields_attributes: fieldsAttributes.map(field => {
                    const { title, required, showInList, ...rest } = field;
                    return { display_name: title, is_required: required, show_in_list: showInList, ...rest };
                }),
            },
            virtual_existing_attributes: attributesVirtual.map(a => {
                const { pimId, required, ...rest } = a;
                return {
                    pim_identifier: pimId,
                    is_required: required,
                    ...rest,
                } as IDataCategorySettingsAttributeVirtual;
            }),
        });
        return this.getData({ id: ACTION_ID_CATEGORIES_SAVE, url: `/${currentApp}/${category_name}/settings`, method: 'PUT', request });
    }

    // PRODUCTS
    fetchCategoryProducts({
        currentApp,
        categoryName,
        page,
        pageSize,
        search,
        sort,
        filterActive,
        filterOnlyForPresale,
        filterVirtual,
    }: IRequestCategoryProducts) {
        let newSort = sort;
        if (newSort) {
            const isReversed = newSort[0] === '-';
            if (isReversed) {
                newSort = newSort.substring(1);
            }
            for (let sortName in columnsNames) {
                if (columnsNames[sortName] === newSort) {
                    newSort = sortName;
                    break;
                }
            }
            newSort = `${isReversed ? '-' : ''}${newSort}`;
        }
        return this.getData({
            id: ACTION_ID_PRODUCTS_FETCH,
            url:
                `/${currentApp}/items/${categoryName}` +
                `?page=${page}` +
                `&page_size=${pageSize}` +
                `&search=${search}` +
                `&sort=${newSort}` +
                `${filterActive ? `&is_active=${filterActive === 'active'}` : ''}` +
                `${filterOnlyForPresale ? `&only_for_presale=${filterOnlyForPresale === 'only-for-presale'}` : ''}` +
                `${filterVirtual ? `&is_virtual=${filterVirtual === 'virtual'}` : ''}`,
        });
    }

    fetchCategoryProductForm(currentApp: string, id: 'form' | number, categoryName: string, isVirtual: boolean) {
        return this.getData({
            id: ACTION_ID_PRODUCTS_FETCH_FORM,
            url: `/${currentApp}/${categoryName}/${id}${id === 'form' ? `?is_virtual=${isVirtual}` : ''}`,
        });
    }

    fetchProductAssociations(currentApp: string, id: number) {
        return this.getData({ id: ACTION_ID_PRODUCTS_FETCH_ASSOCIATIONS, url: `/apps/${currentApp}/associations/${id}` });
    }

    saveProductAssociations(currentApp: string, id: number, associationsList: IProductAssociationsItem[]) {
        const request = JSON.stringify(associationsList);
        return this.getData({
            id: ACTION_ID_PRODUCTS_SAVE_ASSOCIATIONS,
            url: `/apps/${currentApp}/associations/${id}`,
            method: 'PUT',
            request,
        });
    }

    fetchProductPriceAndAmount(app: string, id: number) {
        return this.getData({
            id: ACTION_ID_PRODUCTS_FETCH_PRODUCT_PRICE_AND_AMOUNT,
            url: `/${app}/rs/items/price?item_id=${id}`,
        });
    }

    fetchProductPriceAndReport(app: string, id: number) {
        return this.getData({
            id: ACTION_ID_PRODUCTS_FETCH_PRODUCT_PRICE_AND_REPORT,
            url: `/${app}/rs/items/price/report?item_id=${id}`,
            requestType: 'file',
        });
    }

    fetchCategoryProductsExcel(currentApp: string, categoryName: string, isVirtual: boolean) {
        return this.getData({
            id: ACTION_ID_PRODUCTS_FETCH_EXCEL,
            url: `/${currentApp}/items/excel/${categoryName}?is_virtual=${isVirtual}`,
            requestType: 'file',
        });
    }

    uploadCategoryProductsExcel(currentApp: string, categoryName: string, formData: FormData, isVirtual: boolean) {
        return this.getData({
            id: ACTION_ID_PRODUCTS_EXCEL_UPLOAD,
            url: `/${currentApp}/items/excel/${categoryName}?is_virtual=${isVirtual}`,
            method: 'POST',
            request: formData,
            requestType: 'formData',
        });
    }

    saveProduct(currentApp: string, isNew: boolean, product: IProductSave, categoryName: string, isVirtual: boolean) {
        const { id, ...rest } = product;
        const request: IProductSave = { ...rest };

        if (!isNew && id) {
            request.id = Number(id);
        }

        return this.getData({
            id: ACTION_ID_PRODUCTS_SAVE,
            url: `/${currentApp}/${categoryName}?is_virtual=${isVirtual}`,
            method: isNew ? 'POST' : 'PUT',
            request: JSON.stringify(request),
        });
    }

    fetchProductImage(name: string) {
        return this.getData({ id: ACTION_ID_PRODUCTS_FETCH_IMAGE, url: `/upload/photo?name=${name}`, requestType: 'file' });
    }

    uploadProductImage(formData: FormData) {
        return this.getData({
            id: ACTION_ID_PRODUCTS_IMAGE_UPLOAD,
            url: '/upload/photo',
            method: 'POST',
            request: formData,
            requestType: 'formData',
        });
    }

    deleteProduct(currentApp: string, id: number) {
        const request = { items: [id] };
        return this.getData({
            id: ACTION_ID_PRODUCTS_DELETE,
            url: `/${currentApp}/items`,
            method: 'DELETE',
            request: JSON.stringify(request),
        });
    }

    // NEWS
    fetchNewsAdmin({ page, pageSize }: IRequestList) {
        return this.getData({
            id: ACTION_ID_NEWS_ADMIN_FETCH,
            url: `/admin/news?page=${page}&page_size=${pageSize}`,
        });
    }

    fetchNewsAdminItem(id: number) {
        return this.getData({
            id: ACTION_ID_NEWS_ADMIN_ITEM_FETCH,
            url: `/admin/news/${id}`,
        });
    }

    updateNewsAdminItem(item: INewsAdminItemSave, isNew: boolean, id?: number) {
        const request: INewsAdminItemSave = { ...item };
        if (id) {
            request.id = id;
        }
        return this.getData({
            id: ACTION_ID_NEWS_ADMIN_ITEM_UPDATE,
            url: `/admin/news${!isNew ? `/${id}` : ''}`,
            method: isNew ? 'POST' : 'PUT',
            request: JSON.stringify(item),
        });
    }

    deleteNewsAdminItem(id: number) {
        return this.getData({
            id: ACTION_ID_NEWS_ADMIN_ITEM_DELETE,
            url: `/admin/news/${id}`,
            method: 'DELETE',
        });
    }

    fetchNews({ page, pageSize }: IRequestList) {
        return this.getData({
            id: ACTION_ID_NEWS_FETCH,
            url: `/news?page=${page}&page_size=${pageSize}`,
        });
    }

    fetchNewsDetail(id: number) {
        return this.getData({
            id: ACTION_ID_NEWS_FETCH_DETAIL,
            url: `/news/${id}`,
        });
    }

    // FEEDBACK
    fetchFeedbackAdmin({ search, status, page, pageSize }: IRequestListFeedback) {
        return this.getData({
            id: ACTION_ID_FEEDBACK_ADMIN_FETCH,
            url: `/admin/feedback?page=${page}&page_size=${pageSize}${search ? `&search=${search}` : ''}${status ? `&status=${status}` : ''}`,
        });
    }

    fetchFeedbackAdminItem(id: number) {
        return this.getData({
            id: ACTION_ID_FEEDBACK_ADMIN_ITEM_FETCH,
            url: `/admin/feedback/${id}`,
        });
    }

    createFeedbackItem(request: IFeedbackItemCreateRequest) {
        return this.getData({
            id: ACTION_ID_FEEDBACK_ITEM_CREATE,
            url: '/feedback',
            method: 'POST',
            request: JSON.stringify(request),
        });
    }

    updateFeedbackItem({ id, status }: IFeedbackItemUpdateRequest) {
        return this.getData({
            id: ACTION_ID_FEEDBACK_ITEM_UPDATE,
            url: `/admin/feedback/${id}`,
            method: 'PATCH',
            request: JSON.stringify({ status }),
        });
    }

    // EDUCATION
    fetchEducation() {
        return this.getData({
            id: ACTION_ID_EDUCATION_FETCH,
            url: `/education`,
        });
    }

    fetchEducationAdminItem(id: number) {
        return this.getData({
            id: ACTION_ID_EDUCATION_ADMIN_ITEM_FETCH,
            url: `/admin/education/${id}`,
        });
    }

    updateEducationAdminItem(item: IEducationItemSave, isNew: boolean, id?: number) {
        const request: IEducationItemSaveRequest = { link: item.link, link_name: item.linkName };
        if (id) {
            request.id = id;
        }
        return this.getData({
            id: ACTION_ID_EDUCATION_ADMIN_ITEM_UPDATE,
            url: `/admin/education${!isNew ? `/${id}` : ''}`,
            method: isNew ? 'POST' : 'PUT',
            request: JSON.stringify(request),
        });
    }

    deleteEducationAdminItem(id: number) {
        return this.getData({
            id: ACTION_ID_EDUCATION_ADMIN_ITEM_DELETE,
            url: `/admin/education/${id}`,
            method: 'DELETE',
        });
    }
}

async function setResponse(response: any, requestType: TRequestType) {
    const { ok, status, headers } = response;
    const success = [200, 201, 204];
    const successResult = { success: true };
    const isFileRequest = requestType === 'file';

    try {
        const result = isFileRequest ? await response.blob() : await response.json();

        if (isFileRequest) {
            const { size, type } = result;
            return {
                file: result,
                fileSize: size,
                fileType: type,
                fileName: headers.get('Content-Disposition')?.split('filename=')[1],
                fileExtension: type?.split('/').pop(),
            };
        }

        if (result === null) {
            return successResult;
        }

        return result;
    } catch (error) {
        if (ok && success.includes(status)) {
            return successResult;
        } else {
            throw new Error(getError(error as TFetchError));
        }
    }
}

export default Actions;
